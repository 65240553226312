import {push} from 'connected-react-router';
import jwt from 'jsonwebtoken';
import * as api from '../api';
import {showMessage, hideMessage} from './messages';

export const partnerProfileReceived = profile => ({
  type: 'PROFILE_RECEIVED',
  profile
});

export const refreshPartnerProfile = () => async (dispatch, getState) => {
  const {login: {token, profile}} = getState();
  const tokenData = jwt.decode(token);
  const res = await api.getPartnerProfile({uid: tokenData.id, email: profile.user.email, role: tokenData.role}, token);
  if (res.status === 200) {
    dispatch(partnerProfileReceived(res.json));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const savePartnerProfile = profile => async (dispatch, getState) => {
  const {login: {token}} = getState();
  delete profile.imagesNames;

  const res = await api.savePartnerProfile(profile, token);
  if (res.status === 200) {

    dispatch(refreshPartnerProfile());
    dispatch(showMessage('PROFILE', 'PROFILE_UPDATED'));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnerFieldsActiveTab = activeTab => ({
  type: 'PARTNER_FIELDS_ACTIVE_TAB',
  activeTab
});

export const deletePartnerImageFile = file => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteImageFile(token, file);

  if (res.status === 200) {
    dispatch(refreshPartnerProfile());
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deletePartnerField = fieldId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePartnerField(token, fieldId);

  if (res.status === 200) {
    dispatch(getOwnedFields());
    dispatch(push('/partner-fields'));
    dispatch(showMessage('FIELDS', 'FIELD_DELETED'));
  } else if(res.json.error === 'future_booking') {
      dispatch(showMessage('FIELDS', 'FUTURE_BOOKING_ERROR_FIELD'));
  } else if(res.json.error === 'future_video') {
      dispatch(showMessage('FIELDS', 'FUTURE_VIDEO_ERROR'));
  } else {
    dispatch(showMessage('FIELDS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deletePartnerService = serviceId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePartnerService(token, serviceId);

  if (res.status === 200) {
    dispatch(getOwnedServices());
    dispatch(push('/partner-fields'));
    dispatch(showMessage('CENTER_SERVICES', 'SERVICE_DELETED'));
  } else if(res.json.error === 'future_booking') {
      dispatch(showMessage('CENTER_SERVICES', 'FUTURE_BOOKING_ERROR_SERVICE'));
  } else {
    dispatch(showMessage('CENTER_SERVICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const ownedFieldsReceived = ownedFields => ({
  type: 'OWNED_FIELDS_RECEIVED',
  ownedFields
});

export const getOwnedFields = (ownedFieldId = null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const tokenData = jwt.decode(token);
  const res = await api.getPartnerFields(tokenData.id, token);
  if (res.status === 200) {
    const fields = res.json;
    dispatch(ownedFieldsReceived(fields));
    if (ownedFieldId) {
      const f = fields.find(f => f.id === ownedFieldId);
      if (f) {
        dispatch(ownedFieldSelected(f));
      }
    }
  } else {
    dispatch(showMessage('FIELDS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const ownedServicesReceived = ownedServices => ({
  type: 'OWNED_SERVICES_RECEIVED',
  ownedServices
});

export const getOwnedServices = (ownedServiceId = null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const tokenData = jwt.decode(token);
  const res = await api.getPartnerServices(tokenData.id, token);
  if (res.status === 200) {
    const services = res.json;
    dispatch(ownedServicesReceived(services));
    if (ownedServiceId) {
      const s = services.find(s => s.id === ownedServiceId);
      if (s) {
        dispatch(ownedServiceSelected(s));
      }
    }
  } else {
    dispatch(showMessage('SERVICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const newOwnedField = (initialValues = {}) => ({
  type: 'NEW_OWNED_FIELD',
  initialValues
});

export const newOwnedService = (initialValues = {}) => ({
  type: 'NEW_OWNED_SERVICE',
  initialValues
});

export const saveOwnedField = fieldData => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  if (fieldData.id === null) {
    res = await api.savePartnerField(fieldData, token);
  } else {
    res = await api.updatePartnerField(fieldData, token);
  }

  if (res.status === 200 || res.status === 201) {
    if (fieldData.id === null) {
      fieldData.id = res.json.fieldId;
    }

    dispatch(getOwnedFields());
    dispatch(push('/partner-fields'));
    dispatch(showMessage('FIELDS', 'FIELD_SAVED'));
  } else {
    dispatch(showMessage('FIELDS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const saveOwnedService = serviceData => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  if (serviceData.id === null) {
    res = await api.savePartnerService(serviceData, token);
  } else {
    res = await api.updatePartnerService(serviceData, token);
  }

  if (res.status === 200 || res.status === 201) {
    if (serviceData.id === null) {
      serviceData.id = res.json.serviceId;
    }

    dispatch(getOwnedServices());
    dispatch(push('/partner-fields'));
    dispatch(showMessage('CENTER_SERVICES', 'FIELD_SAVED'));
  } else {
    dispatch(showMessage('CENTER_SERVICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const ownedFieldSelected = ownedField => ({
  type: 'OWNED_FIELD_SELECTED',
  ownedField
});

export const ownedServiceSelected = ownedService => ({
  type: 'OWNED_SERVICE_SELECTED',
  ownedService
});

export const deleteFieldImageFile = (file, fieldId) => async (dispatch, getState) => {
  const {login: {token}, partner: {ownedField}} = getState();
  const res = await api.deleteFieldImageFile(token, file, fieldId);

  if (res.status === 200) {
    dispatch(refreshPartnerProfile());
    dispatch(getOwnedFields(ownedField && ownedField.id ? ownedField.id : null));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deleteServiceImageFile = (file, serviceId) => async (dispatch, getState) => {
  const {login: {token}, partner: {ownedService}} = getState();
  const res = await api.deleteServiceImageFile(token, file, serviceId);

  if (res.status === 200) {
    dispatch(refreshPartnerProfile());
    dispatch(getOwnedServices(ownedService && ownedService.id ? ownedService.id : null));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const ownedFieldSlotsReceived = ownedFieldSlots => ({
  type: 'OWNED_FIELD_SLOTS_RECEIVED',
  ownedFieldSlots
});

export const getOwnedFieldSlots = (fieldId, bookingPartner, slotType = 'field', targetDate = new Date(), searchRange = 'WEEK') => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const tokenData = jwt.decode(token);
  let res;
  if(slotType === 'field'){
    res = await api.getFieldSlots(tokenData.id, token, fieldId, bookingPartner, targetDate, searchRange);
  }
  else{
    res = await api.getServiceSlots(tokenData.id, token, fieldId, bookingPartner, targetDate, searchRange);
  }
  if (res.status === 200) {
    dispatch(ownedFieldSlotsReceived(res.json));
  } else {
    dispatch(showMessage('FIELDS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deleteOwnedFieldSlots = slotData => async (dispatch, getState) => {
  const targetDate = new Date(slotData.startDatetime);
  const {login: {token}} = getState();
  const res = await api.deleteFieldSlots({fieldId: slotData.fieldId, serviceId: slotData.serviceId, weekDays: slotData.weekDays, startDatetime: slotData.startDatetime, endDatetime: slotData.endDatetime, untilDate: slotData.untilDate}, token);
  if (res.status === 200) {
    dispatch(hideSlotModal());
    if(slotData.fieldId != null)
      dispatch(getOwnedFieldSlots(slotData.fieldId, false, 'field', targetDate));
    else dispatch(getOwnedFieldSlots(slotData.serviceId, false, 'service', targetDate));
    dispatch(showMessage('CALENDAR', 'CALENDAR_EVENT_DELETED'));
  } else {
    if(res.json.error === 'a_transaction_exists') dispatch(showMessage('CALENDAR', 'TRANSACTION_EXISTS'));
    else if(res.json.error === 'present_in_my_video_area') dispatch(showMessage('CALENDAR', 'PRESENT_IN_MY_VIDEO'));
    else dispatch(showMessage('CALENDAR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showSlotModal = () => ({
  type: 'SHOW_SLOT_MODAL'
});

export const hideSlotModal = () => ({
  type: 'HIDE_SLOT_MODAL'
});

export const newOwnedFieldSlot = (initialValues = {}) => ({
  type: 'NEW_OWNED_FIELD_SLOT',
  initialValues
});

export const saveFieldSlot = slotData => async (dispatch, getState) => {
  const targetDate = new Date(slotData.startDatetime);
  const {login: {token}} = getState();
  if (slotData.state === 'reserved') {
    slotData.presetPlaces = slotData.availablePlaces = 1
  } else if (slotData.state === 'unavailable') {
    slotData.presetPlaces = slotData.availablePlaces = 0
  } else if (slotData.state === 'available' && slotData.presetPlaces === 0) {
    slotData.presetPlaces = slotData.availablePlaces = 1
  }

  let res = null;
  if (slotData.id === null) {
    slotData.changing = false;
    res = await api.saveFieldSlot(slotData, token);
  } else {
    slotData.changing = true;
    res = await api.saveFieldSlot(slotData, token);
  }

  if (res.status === 200 || res.status === 201) {
    if (slotData.id === null) {
      slotData.id = res.json.slotId;
    }
    if(slotData.fieldId != null)
      dispatch(getOwnedFieldSlots(slotData.fieldId, false, 'field', targetDate));
    else dispatch(getOwnedFieldSlots(slotData.serviceId, false, 'service', targetDate));
    dispatch(hideSlotModal());
    if (slotData.fieldId !== null) dispatch(push('/partner-field-calendar'));
    else dispatch(push('/partner-service-calendar'));
    dispatch(showMessage('CALENDAR', 'CALENDAR_EVENT_SAVED'));
  } else if (res.json.error === 'invalid_order_datetime') {
    dispatch(showMessage('CALENDAR', 'CALENDAR_EVENT_BAD_ENDTIME'));
  } else if (res.json.error === 'slot_too_long') {
    dispatch(showMessage('CALENDAR', 'SLOT_TOO_LONG'));
  } else if (res.json.error === 'unavailable_slot_too_long') {
    dispatch(showMessage('CALENDAR', 'SLOT_UNAVAILABLE_TOO_LONG')); 
  } else if (res.status === 400 && res.json.error === 'overlapping_slot') {
    dispatch(showMessage('CALENDAR', 'CALENDAR_EVENT_OVERLAPPING_SLOT'));
  } else if (res.json.error === 'a_transaction_exists') {
    dispatch(showMessage('CALENDAR', 'CANNOT_OVERWRITE_SLOT'));
  } else if (res.json.error === 'a_user_video_exists') {
    dispatch(showMessage('CALENDAR', 'CANNOT_OVERWRITE_SLOT'));
  } else if (res.json.error === 'no_players_selected') {
    dispatch(showMessage('ADD_PLAYER', 'PLAYERS_SELECTION'));
  } else {
    dispatch(showMessage('CALENDAR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const ownedFieldSlotSelected = ownedFieldSlot => ({
  type: 'OWNED_FIELD_SLOT_SELECTED',
  ownedFieldSlot
});

export const ownedFieldBookingsReceived = ownedFieldBookings => ({
  type: 'OWNED_FIELD_BOOKINGS_RECEIVED',
  ownedFieldBookings
});

export const getOwnedFieldBookings = (targetDate = null, searchRange = 'MONTH', resourceType = 'field') => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getBookings(token, targetDate, searchRange, resourceType);
  if (res.status === 200) {
    dispatch(ownedFieldBookingsReceived(res.json));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showBookingModal = () => ({
  type: 'SHOW_BOOKING_MODAL'
});

export const hideBookingModal = () => ({
  type: 'HIDE_BOOKING_MODAL'
});

export const newOwnedFieldBooking = (initialValues = {}) => ({
  type: 'NEW_OWNED_FIELD_BOOKING',
  initialValues
});

export const saveBooking = bookingData => async (dispatch, getState) => {
  const {login: {token}, partner: {ownedField, ownedFieldSlot, bookingSlots}} = getState();
  const targetDate = new Date(ownedFieldSlot ? ownedFieldSlot.startDatetime : bookingSlots[0].startDatetime);
  let res = null;
  if (bookingData.bookingId && !bookingData.multiBooking) {
    res = await api.updateBooking(bookingData, token);
  } else {
    res = await api.saveBooking(bookingData, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SAVED'));
  } else if (res.json.error === 'slot_too_long') {
    dispatch(showMessage('BOOKINGS', 'SLOT_TOO_LONG'));
  } else if (res.json.error === 'unavailable_slot_too_long') {
    dispatch(showMessage('BOOKINGS', 'SLOT_UNAVAILABLE_TOO_LONG'));
  } else if (res.json.error === 'old_slot') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
  } else if (res.json.error === 'slot_already_booked') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SOLD_OUT'));
  } else if (res.json.error === 'partner_cannot_modify_before_the_event_start') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_NOT_STARTED'));
  } else if (res.json.error === 'partner_cannot_modify_online_paid_bookings') {
    dispatch(showMessage('BOOKINGS', 'CANNOT_MODIFY_ONLINE_PAID_BOOKING'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(ownedField ? ownedField.id : null, true, 'field', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK'));
  dispatch(hideBookingModal());
};

export const saveServiceBooking = bookingData => async (dispatch, getState) => {
  const {login: {token}, partner: {ownedService, ownedFieldSlot, bookingSlots}} = getState();
  const targetDate = new Date(ownedFieldSlot ? ownedFieldSlot.startDatetime : bookingSlots[0].startDatetime);
  let res = null;
  if (bookingData.bookingId && !bookingData.multiBooking) {
    res = await api.updateServiceBooking(bookingData, token);
  } else {
    res = await api.saveServiceBooking(bookingData, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SAVED'));
  } else if (res.json.error === 'slot_too_long') {
    dispatch(showMessage('BOOKINGS', 'SLOT_TOO_LONG'));
  } else if (res.json.error === 'unavailable_slot_too_long') {
    dispatch(showMessage('BOOKINGS', 'SLOT_UNAVAILABLE_TOO_LONG'));
  } else if (res.json.error === 'old_slot') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
  } else if (res.json.error === 'slot_already_booked') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SOLD_OUT'));
  } else if (res.json.error === 'partner_cannot_modify_before_the_event_start') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_NOT_STARTED'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(ownedService ? ownedService.id : null, true, 'service', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK', 'service'));
  dispatch(hideBookingModal());
};

export const saveUserBooking = bookingData => async (dispatch, getState) => {
  const {login: {token}, partner: {ownedField, ownedFieldSlot}} = getState();
  const targetDate = new Date(ownedFieldSlot.startDatetime);
  let res = null;
  res = await api.updateUserBooking(bookingData, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SAVED'));
  } else if (res.json.error === 'partner_cannot_modify_online_paid_bookings') {
    dispatch(showMessage('BOOKINGS', 'CANNOT_MODIFY_ONLINE_PAID_BOOKING'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(ownedField ? ownedField.id : null, true, 'field', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK'));
  dispatch(hideBookingModal());
}

export const saveServiceUserBooking = bookingData => async (dispatch, getState) => {
  const {login: {token}, partner: {ownedService, ownedFieldSlot}} = getState();
  const targetDate = new Date(ownedFieldSlot.startDatetime);
  let res = null;
  res = await api.updateUserBooking(bookingData, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SAVED'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(ownedService ? ownedService.id : null, true, 'service', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK', 'service'));
  dispatch(hideBookingModal());
}

export const deleteBooking = (fieldId, bookingId) => async (dispatch, getState) => {
  dispatch(showMessage('BOOKINGS', 'BOOKING_DELETING'));
  const {login: {token}, partner: {ownedFieldSlot}} = getState();
  const targetDate = new Date(ownedFieldSlot.startDatetime);
  const res = await api.deleteBooking(bookingId, token);
  dispatch(hideMessage());

  if (res.status !== 200 && res.status !== 201) {
    if(res.json.error === 'partner_delete_booking_rejected')
      dispatch(showMessage('BOOKINGS', "PARTNER_DELETE_BOOKING_REJECTED",{hours: res.json.unbookable_days * 24}))
    else dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(fieldId, true, 'field', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK'));
  dispatch(hideBookingModal());
};

export const deleteServiceBooking = (serviceId, bookingId) => async (dispatch, getState) => {
  dispatch(showMessage('BOOKINGS', 'BOOKING_DELETING'));
  const {login: {token}, partner: {ownedFieldSlot}} = getState();
  const targetDate = new Date(ownedFieldSlot.startDatetime);
  const res = await api.deleteServiceBooking(bookingId, token);
  dispatch(hideMessage());

  if (res.status !== 200 && res.status !== 201) {
    if(res.json.error === 'partner_delete_booking_rejected')
      dispatch(showMessage('BOOKINGS', "PARTNER_DELETE_BOOKING_REJECTED",{hours: res.json.unbookable_days * 24}))
    else dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(serviceId, true, 'service', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK', 'service'));
  dispatch(hideBookingModal());
};

export const deleteUserBooking = (fieldId, userBookingId) => async (dispatch, getState) => {
  dispatch(showMessage('BOOKINGS', 'BOOKING_DELETING'));
  const {login: {token}, partner: {ownedFieldSlot}} = getState();
  const targetDate = new Date(ownedFieldSlot.startDatetime);
  const res = await api.deleteUserBooking(userBookingId, token);
  dispatch(hideMessage());

  if (res.status !== 200 && res.status !== 201) {
    if(res.json.error === 'partner_delete_booking_rejected')
      dispatch(showMessage('BOOKINGS', "PARTNER_DELETE_BOOKING_REJECTED",{hours: res.json.unbookable_days * 24}))
    else if(res.json.error === 'group_booking_active')
    dispatch(showMessage('BOOKINGS', "GROUP_BOOKING_DELETE_DENIED"))
    else dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(fieldId, true, 'field', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK'));
  dispatch(hideBookingModal());
};

export const deleteServiceUserBooking = (serviceId, userBookingId) => async (dispatch, getState) => {
  dispatch(showMessage('BOOKINGS', 'BOOKING_DELETING'));
  const {login: {token}, partner: {ownedFieldSlot}} = getState();
  const targetDate = new Date(ownedFieldSlot.startDatetime);
  const res = await api.deleteServiceUserBooking(userBookingId, token);
  dispatch(hideMessage());

  if (res.status !== 200 && res.status !== 201) {
    if(res.json.error === 'partner_delete_booking_rejected')
      dispatch(showMessage('BOOKINGS', "PARTNER_DELETE_BOOKING_REJECTED",{hours: res.json.unbookable_days * 24}))
    else dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getOwnedFieldSlots(serviceId, true, 'service', targetDate, 'WEEK'));
  dispatch(getOwnedFieldBookings(targetDate, 'WEEK', 'service'));
  dispatch(hideBookingModal());
};

export const ownedFieldBookingSelected = ownedFieldBooking => ({
  type: 'OWNED_FIELD_BOOKING_SELECTED',
  ownedFieldBooking
});

export const printReport = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.printReport(filters, token);
  
  if (res.status === 400) {
    if (res.json.error === 'no_report') {
      dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
    } else {
      dispatch(showMessage('REPORT', `Error ${res.status}: ${res.json.error}`));
    }
  } else if (res.status === 504) {
    dispatch(showMessage('REPORT', 'REPORT_TOO_LONG'));
  }
};

export const bookingMultiSlotsSelected = bookingSlots => ({
  type: 'PARTNER_BOOKING_MULTI_SLOT_SELECTED',
  bookingSlots
});


export const updatePartnerLanguage = language => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.updateUserLanguage(language, token);

  if (res.status !== 200) {
    dispatch(showMessage('CHANGE_LANGUAGE', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(refreshPartnerProfile());
};

export const newOwnedEvent = (initialValues = {}) => ({
  type: 'NEW_OWNED_EVENT',
  initialValues
});

export const saveOwnedEvent = eventData => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  if (eventData.id === null) {
    res = await api.savePartnerEvent(eventData, token);
  } else {
    res = await api.updatePartnerEvent(eventData, token);
  }

  if (res.status === 200 || res.status === 201) {
    if (eventData.id === null) {
      eventData.id = res.json.eventId;
    }

    dispatch(getOwnedEvents());
    dispatch(push('/partner-events'));
    dispatch(showMessage('SPECIAL_EVENTS', 'SPECIAL_EVENT_SAVED'));
  } else if (res.json.error === 'invalid_datetime') {
    dispatch(showMessage('SPECIAL_EVENTS', 'SPECIAL_EVENT_DATETIME_ERROR'));
  } else {
    dispatch(showMessage('SPECIAL_EVENTS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const ownedEventsReceived = ownedEvents => ({
  type: 'OWNED_EVENTS_RECEIVED',
  ownedEvents
});

export const ownedEventSelected = ownedEvent => ({
  type: 'OWNED_EVENT_SELECTED',
  ownedEvent
});

export const getOwnedEvents = (ownedEventId = null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const tokenData = jwt.decode(token);
  const res = await api.getPartnerEvents(tokenData.id, token);
  if (res.status === 200) {
    const events = res.json;
    dispatch(ownedEventsReceived(events));
    if (ownedEventId) {
      const e = events.find(e => e.id === ownedEventId);
      if (e) {
        dispatch(ownedEventSelected(e));
      }
    }
  } else {
    dispatch(showMessage('SPECIAL_EVENTS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deletePartnerEvent = eventId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePartnerEvent(token, eventId);

  if (res.status === 200) {
    dispatch(getOwnedEvents());
    dispatch(push('/partner-events'));
    dispatch(showMessage('SPECIAL_EVENTS', 'SPECIAL_EVENT_DELETED'));
  } else {
    dispatch(showMessage('SPECIAL_EVENTS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deleteEventImageFile = (file, eventId) => async (dispatch, getState) => {
  const {login: {token}, partner: {ownedEvent}} = getState();
  const res = await api.deleteEventImageFile(token, file, eventId);

  if (res.status === 200) {
    dispatch(refreshPartnerProfile());
    dispatch(getOwnedEvents(ownedEvent && ownedEvent.id ? ownedEvent.id : null));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const ownedRtvChannelsReceived = ownedRtvChannels => ({
  type: 'OWNED_RTV_CHANNELS_RECEIVED',
  ownedRtvChannels
});

export const ownedRtvChannelSelected = ownedRtvChannel => ({
  type: 'OWNED_RTV_CHANNEL_SELECTED',
  ownedRtvChannel
});

export const getOwnedRtvChannels = (ownedRtvChannelId = null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const tokenData = jwt.decode(token);
  const res = await api.getPartnerRtvChannels(tokenData.id, token);
  if (res.status === 200) {
    const channels = res.json;
    dispatch(ownedRtvChannelsReceived(channels));
    if (ownedRtvChannelId) {
      const s = channels.find(s => s.id === ownedRtvChannelId);
      if (s) {
        dispatch(ownedRtvChannelSelected(s));
      }
    }
  } else {
    dispatch(showMessage('RTV_CHANNELS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const rtvVideosReceived = (rtvVideos, rtvVideosSoon) => ({
  type: 'PARTNER_RTV_VIDEOS_RECEIVED',
  rtvVideos,
  rtvVideosSoon
});

export const getRtvVideos = (partnerUid, rtvChannelId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res1 = await api.getRtvVideos(token, partnerUid, rtvChannelId);
  const res2 = await api.getRtvVideosSoon(token, partnerUid, rtvChannelId);
  if (res1.status === 200 && res2.status === 200) {
    dispatch(rtvVideosReceived(res1.json, res2.json));
  } else {
    dispatch(rtvVideosReceived(null));
  }
};

export const addVideoToPartnerArea = slotId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.addVideoToPlayerArea(slotId, token);
  if (res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_ADDED'));
  } else if (res.json.error === 'exceeded_quota') {
      dispatch(showMessage('MY_VIDEO', 'MY_VIDEO_EXCEEDED_QUOTA'));
  } else if(res.json.error === 'video_already_there') {
      dispatch(showMessage('MY_VIDEO', 'VIDEO_ALREADY_COPIED'));
  } else if(res.json.error === 'video_in_streaming') {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_IN_STREAMING'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getPartnerPermanentVideos());
};

export const requestVideo = slotId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.requestVideo(slotId, token);
  if (res.status === 200) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_REQUESTED'));
  } else if(res.json.error === 'already_requested') {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_ALREADY_REQUESTED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const getPartnerPermanentVideos = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayerPermanentVideos(token);
  if (res.status === 200) {
    dispatch(permanentVideosReceived(res.json));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const permanentVideosReceived = videos => ({
  type: 'PARTNER_PERMANENT_VIDEOS_RECEIVED',
  videos
});

export const deleteRtvChannelImageFile = (file, rtvChannelId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteRtvChannelImageFile(token, file, rtvChannelId);

  if (res.status === 200) {
    dispatch(refreshPartnerProfile());
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};


export const videosStoreReceived = (videosStore, videosStoreSoon) => ({
  type: 'PARTNER_VIDEOS_STORE_RECEIVED',
  videosStore,
  videosStoreSoon
});

export const getVideosStorePartner = (fieldId = null, serviceId = null, fromDatetime = null, toDatetime = null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const tokenData = jwt.decode(token);
  const res1 = await api.getVideosStore(token, tokenData.id, fieldId, serviceId, fromDatetime, toDatetime);
  const res2 = await api.getVideosStoreSoon(token, tokenData.id, fieldId, serviceId, fromDatetime, toDatetime);
  if (res1.status === 200 && res2.status === 200) {
    dispatch(videosStoreReceived(res1.json, res2.json));
  } else {
    dispatch(videosStoreReceived(null));
  }
};

export const storagePricesReceived = prices => ({
  type: 'PARTNER_STORAGE_PRICES_RECEIVED',
  prices
});

export const partnerGetStoragePrices = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getStoragePrices(token);
  if (res.status === 200) {
    dispatch(storagePricesReceived(res.json));
  } else {
    dispatch(showMessage('STORAGE PRICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const changeStorageType = newStorageType => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.changeStorageType(newStorageType, token);
  if (res.status == 200) {
    dispatch(refreshPartnerProfile());
    if (res.json.upgrade)
      dispatch(showMessage('UPGRADE_DONE', 'NEW_STORAGE_LIMIT',{limit: res.json.storage_limit, date: new Date(res.json.expiry_date).toLocaleDateString({weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}));
    else dispatch(showMessage('DOWNGRADE_DONE', 'NEW_STORAGE_LIMIT',{limit: res.json.storage_limit, date: new Date(res.json.expiry_date).toLocaleDateString({weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}));
  } else {
      if(res.json.error === 'storage_type_already_active') dispatch(showMessage('MY_VIDEO_MANAGE_SPACE', 'STORAGE_ALREADY_ACTIVE'));
      else if(res.json.error === 'not_enough_money') dispatch(showMessage('MY_VIDEO_MANAGE_SPACE', 'NOT_ENOUGH_CREDIT'));
      else if(res.json.error === 'not_enough_free_space') dispatch(showMessage('DOWNGRADE_NOT_ALLOWED_NO_FREE_SPACE', 'NOT_ENOUGH_FREE_SPACE'));
      else dispatch(showMessage('MY_VIDEO_MANAGE_SPACE', `Error ${res.status}: ${res.json.error}`));
    }
};

export const permanentVideoSelected = selectedPermanentVideo => ({
  type: 'PARTNER_PERMANENT_VIDEO_SELECTED',
  selectedPermanentVideo
});

export const showVideoHighlightModal = () => ({
  type: 'PARTNER_SHOW_VIDEO_HIGHLIGHT_MODAL'
});

export const hideVideoHighlightModal = () => ({
  type: 'PARTNER_HIDE_VIDEO_HIGHLIGHT_MODAL'
});

export const userTagsReceived = userTags => ({
  type: 'PARTNER_USER_TAGS_RECEIVED',
  userTags
});

export const getUserTagsPartner = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getUserTags(token);
  if (res.status === 200) {
    dispatch(userTagsReceived(res.json));
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const myVideoActiveTab = activeTab => ({
  type: 'PARTNER_MY_VIDEO_ACTIVE_TAB',
  activeTab
});

export const selectVideoForTaggingPartner = video => ({
  type: 'PARTNER_VIDEO_FOR_TAGGING_SELECTED',
  video
});

export const taggedUserVideosReceived = taggedUserVideos => ({
  type: 'PARTNER_TAGGED_USER_VIDEOS_RECEIVED',
  taggedUserVideos
});

export const getTaggedUserVideosPartner = (tags = []) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getUserVideos(tags, token);
  if (res.status === 200) {
    dispatch(taggedUserVideosReceived(res.json));
  } else {
    dispatch(showMessage('USER_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showStorageModal = () => ({
  type: 'PARTNER_SHOW_STORAGE_MODAL'
});

export const hideStorageModal = () => ({
  type: 'PARTNER_HIDE_STORAGE_MODAL'
});

export const playlistsReceived = playlists => ({
  type: 'PARTNER_PLAYLISTS_RECEIVED',
  playlists
});

export const userVideoTagsReceived = userVideoTags => ({
  type: 'PARTNER_USER_VIDEO_TAGS_RECEIVED',
  userVideoTags
});

export const getUserVideoTags = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getUserVideoTags(videoId, token);
  if (res.status === 200) {
    dispatch(userVideoTagsReceived(res.json));
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showUserTagModal = () => ({
  type: 'PARTNER_SHOW_USER_TAG_MODAL'
});

export const hideUserTagModal = () => ({
  type: 'PARTNER_HIDE_USER_TAG_MODAL'
});

export const deletePartnerPermanentVideo = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePlayerPermanentVideo(videoId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_DELETED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
  dispatch(refreshPartnerProfile());
  dispatch(getPartnerPermanentVideos());
  dispatch(getTaggedUserVideosPartner());
};

export const modifyPartnerPermanentVideo = (videoId, videoData) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  await api.modifyPlayerPermanentVideo(videoId, videoData, token);
  dispatch(getPartnerPermanentVideos());
};

export const deletePlaylist = playlistId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePlaylist(playlistId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'PLAYLIST_DELETED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
  dispatch(refreshPartnerProfile());
  dispatch(getPartnerPlaylists());
};

export const modifyPlaylist = (playlistId, videoData) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  await api.modifyPlaylist(playlistId, videoData, token);
  dispatch(getPartnerPlaylists());
};

export const getPartnerPlaylists = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayerPlaylists(token);
  if (res.status === 200) {
    dispatch(playlistsReceived(res.json));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showVideoSharingModal = () => ({
  type: 'PARTNER_SHOW_VIDEO_SHARING_MODAL'
});

export const hideVideoSharingModal = () => ({
  type: 'PARTNER_HIDE_VIDEO_SHARING_MODAL'
});

export const newUserTag = () => ({
  type: 'PARTNER_NEW_USER_TAG'
});

export const userTagSelected = selectedUserTag => ({
  type: 'PARTNER_USER_TAG_SELECTED',
  selectedUserTag
});

export const saveUserTag = (userTag, videoId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  if (userTag.id) {
    res = await api.updateUserTag(userTag, token);
  } else {
    res = await api.saveUserTag(userTag, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('TAGS', 'TAG_SAVED'));
    dispatch(getUserVideoTags(videoId));
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getUserTagsPartner());
};

export const deleteUserTag = userTagId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteUserTag(token, userTagId);

  if (res.status === 200) {
    dispatch(getUserTagsPartner());
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showUserVideoTagModal = () => ({
  type: 'PARTNER_SHOW_USER_VIDEO_TAG_MODAL'
});

export const hideUserVideoTagModal = () => ({
  type: 'PARTNER_HIDE_USER_VIDEO_TAG_MODAL'
});

export const newUserVideoTag = (videoId, stopTime, userTagId, startTime) => ({
  type: 'PARTNER_NEW_USER_VIDEO_TAG',
  videoId,
  stopTime,
  userTagId,
  startTime
});

export const deleteUserVideoTags = (userVideoTags, videoId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const promises = userVideoTags.map(async t => {
    const p = await api.deleteUserVideoTag(token, t.id);
    return p;
  });
  await Promise.all(promises);
  dispatch(getUserVideoTags(videoId));
};

export const userVideoTagSelected = selectedUserVideoTag => ({
  type: 'PARTNER_USER_VIDEO_TAG_SELECTED',
  selectedUserVideoTag
});

export const exportHighlights = (userVideoTags, videoId, selectedCameras) => async (dispatch, getState) => {
  const {login: {token}} = getState();

  dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_OPERATION_IN_PROGRESS'));

  const promises = userVideoTags.map(async t => {
    const tracking = [
      {
        seconds: t.startTime,
      },
      {
        seconds: t.stopTime,
      }
    ];

    const p = await api.cutPlayerPermanentVideo(videoId, {videoId, name: t.userTag.label, description: t.description, tracking, selectedCameras}, token);
    return p;
  });
  await Promise.all(promises);
  setIntervalX(() => {
    dispatch(getPartnerPermanentVideos());
  }, 10000, 5);
};

const setIntervalX = (callback, delay, repetitions) => {
  let x = 0;
  const intervalID = window.setInterval(() => {
    callback();
    if (++x === repetitions) {
      window.clearInterval(intervalID);
    }
  }, delay);
};

export const exportPlaylists = (userVideoTags, videoId, selectedCameras) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let body = [];
  userVideoTags.forEach(t => {
    body.push({
      name: t.userTag.label,
      description: t.description,
      tracking: [{seconds: t.startTime}, {seconds: t.stopTime}],
      selectedCameras: selectedCameras
    })
  });
  const res = await api.createPlaylist(videoId, body, token);
  if (res.status === 200) {
    dispatch(showMessage('Playlist', 'PLAYLIST_OPERATION_IN_PROGRESS'));
    setIntervalX(() => {
      dispatch(getPartnerPlaylists());
    }, 10000, 5);
  } else {
    dispatch(showMessage('ERROR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const saveUserVideoTag = userVideoTag => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  const payload = {...userVideoTag};
  delete payload.startStr;
  delete payload.endStr;
  if (payload.id) {
    res = await api.updateUserVideoTag(payload, token);
  } else {
    res = await api.saveUserVideoTag(payload, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(hideUserVideoTagModal());
    dispatch(showMessage('TAGS', 'TAG_SAVED'));
  } else if (res.json.error === 'duration_error') {
    dispatch(showMessage('TAGS', 'TAG_BAD_START_TIME'));
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getUserVideoTags(payload.userVideoId));
};

export const saveHighlight = videoData => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.cutPlayerPermanentVideo(videoData.videoId, videoData, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_OPERATION_IN_PROGRESS'));
  } else if (res.json.error === 'tracking_error') {
    dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_BAD_START_TIME'));
  } else {
    dispatch(showMessage('HIGHLIGHT', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(hideVideoHighlightModal());
  dispatch(permanentVideoSelected(null));
  setIntervalX(() => {
    dispatch(getPartnerPermanentVideos());
  }, 10000, 5);
};

export const permanentVideosSharing = (videoId, sharing, playlistId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.permanentVideosSharing(videoId, sharing, playlistId, token);

  if (res.status === 200 || res.status === 201) {
    // dispatch(showMessage('Condivisione', 'Il video è stato condiviso.'));
  } else {
    dispatch(showMessage('SHARING', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getPartnerPermanentVideos());
  dispatch(getPartnerPlaylists());
};

export const showCameraModal = () => ({
  type: 'PARTNER_SHOW_CAMERA_MODAL'
});

export const hideCameraModal = () => ({
  type: 'PARTNER_HIDE_CAMERA_MODAL'
});

export const highlightOrPlaylistSelected = value => ({
  type: 'PARTNER_HIGHLIGHT_OR_PLAYLIST_SELECTED',
  value
});

export const setSelectedUserVideoTags = tags => ({
  type: 'PARTNER_SET_SELECTED_USER_VIDEO_TAGS',
  tags
});

export const clearSelectedUserVideoTags = () => ({
  type: 'PARTNER_CLEAR_SELECTED_USER_VIDEO_TAG'
});

export const showPartnerVideoCreationModal = () => ({
  type: 'PARTNER_SHOW_VIDEO_CREATION_MODAL'
});

export const hidePartnerVideoCreationModal = () => ({
  type: 'PARTNER_HIDE_VIDEO_CREATION_MODAL'
});

export const createPartnerVideo = data => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.createPartnerVideo(data, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('PARTNER_VIDEOS', 'PARTNER_VIDEO_ADDED'));
    dispatch(getPartnerVideos());
  } else {
    dispatch(showMessage('PARTNER_VIDEOS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnerVideosReceived = videos => ({
  type: 'PARTNER_VIDEOS_RECEIVED',
  videos
});

export const getPartnerVideos = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerVideos(token);
  if (res.status === 200) {
    dispatch(partnerVideosReceived(res.json));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deletePartnerVideo = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePartnerVideo(videoId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_DELETED'));
  } else if(res.json.error === 'public_video'){
    dispatch(showMessage('MY_VIDEO', 'CANNOT_DELETE_PUBLIC_PARTNER_VIDEO'));
  } else if(res.json.error === 'rtv_video'){
      dispatch(showMessage('MY_VIDEO', 'CANNOT_DELETE_RTV_PARTNER_VIDEO'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
  dispatch(refreshPartnerProfile());
  dispatch(getPartnerVideos());
};

export const selectPartnerVideo = video => ({
  type: 'SELECT_PARTNER_VIDEO',
  video
});

export const hidePartnerVideoEditModal = () => ({
  type: 'HIDE_PARTNER_VIDEO_EDIT_MODAL',
});

export const editPartnerVideo = (videoId, data) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.editPartnerVideo(videoId, data, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('PARTNER_VIDEOS', 'PARTNER_VIDEO_EDITED'));
    dispatch(hidePartnerVideoEditModal());
    dispatch(getPartnerVideos());
  } else if(res.json.error === 'no_players_selected'){
    dispatch(showMessage('ADD_PLAYER', 'PLAYERS_SELECTION'));
  } else {
    dispatch(showMessage('PARTNER_VIDEOS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deletePartnerVideoImageFile = (file, videoPartnerId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteVideoImageFile(token, file, videoPartnerId);

  if (res.status === 200) {
    dispatch(refreshPartnerProfile());
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showPlayersModal = () => ({
  type: 'PARTNER_SHOW_PLAYERS_MODAL'
});

export const hidePlayersModal = () => ({
  type: 'PARTNER_HIDE_PLAYERS_MODAL'
});

export const playersReceived = players => ({
  type: 'PARTNER_PLAYERS_LIST_RECEIVED',
  players
});

export const cleanPlayersReceived = () => ({
  type: 'PARTNER_PLAYERS_CLEAN_LIST_RECEIVED',
});

export const searchPlayers = (nickname, name, surname, affiliated) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.partnerGetPlayers(nickname, name, surname, affiliated, token);
  if (res.status === 200) {
    dispatch(playersReceived(res.json));
  } else {
    dispatch(showMessage('SEARCH', `Error ${res.status}: ${res.json.error}`));
  }
};

export const rtvCategoriesReceived = categories => ({
  type: 'PARTNER_RTV_CATEGORIES_RECEIVED',
  categories
});

export const partnerGetRtvCategories = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.partnerGetRtvCategories(token);
  if (res.status === 200) {
    dispatch(rtvCategoriesReceived(res.json));
  } else {
    dispatch(showMessage('RTV_CATEGORIES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const selectCategory = category => ({
  type: 'PARTNER_SELECT_RTV_CATEGORY',
  category
});

export const openFixedCostsModal = () => ({
  type: 'PARTNER_OPEN_FIXED_COSTS_MODAL',
});

export const closeFixedCostsModal = () => ({
  type: 'PARTNER_CLOSE_FIXED_COSTS_MODAL',
});

export const createFixedCost = values => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.partnerCreateFixedCost(values, token);
  if (res.status === 201) {
    dispatch(getOwnedFields(values.fieldId));
  } else {
    dispatch(showMessage('FIXED_COSTS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deleteFixedCost = (costId, fieldId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.partnerDeleteFixedCost(costId, token);
  if (res.status === 200) {
    dispatch(getOwnedFields(fieldId));
  } else {
    dispatch(showMessage('FIXED_COSTS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const reportPlayers = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.partnerReportPlayers(token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const showAffiliationModal = () => ({
  type: 'PARTNER_SHOW_AFFILIATION_MODAL',
});

export const hideAffiliationModal = () => ({
  type: 'PARTNER_HIDE_AFFILIATION_MODAL',
});

export const affiliationsReceived = affiliations => ({
  type: 'PARTNER_AFFILIATIONS_RECEIVED',
  affiliations
});

export const partnerGetAffiliations = () => async (dispatch, getState) => {
  const {login: {token, profile}} = getState();
  const res = await api.partnerGetAffiliations(token, profile.uid);
  if (res.status === 200) {
    dispatch(affiliationsReceived(res.json));
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const editAffiliation = (data) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.editAffiliation(data, token);
  if (res.status === 200) {
    dispatch(partnerGetAffiliations());
    dispatch(getAffiliatedPlayers());
    dispatch(hideAffiliationModal());
  } else if (res.json.error === 'fixed_datetime_in_past') {
    dispatch(showMessage('MEMBERSHIPS', 'FIXED_DATETIME_IN_PAST'));
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const deleteAffiliation = (affiliationId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteAffiliation(affiliationId, token);
  if (res.status === 200) {
    dispatch(partnerGetAffiliations());
  } else {
    if(res.json.error === 'affiliation_has_players'){
      dispatch(showMessage('MEMBERSHIPS', 'AFFILIATION_NOT_DELETE_WITH_PLAYERS'));
    } else {
      dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
    }
  }
}

export const createAffiliation = (data) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.createAffiliation(data, token);
  if (res.status === 201) {
    dispatch(partnerGetAffiliations());
    dispatch(hideAffiliationModal());
  } else if (res.json.error === 'fixed_datetime_in_past') {
    dispatch(showMessage('MEMBERSHIPS', 'FIXED_DATETIME_IN_PAST'));
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const selectAffiliation = affiliation => ({
  type: 'PARTNER_SELECT_AFFILIATION',
  affiliation
});

export const activateDeactivateAffiliation = (affiliationId, active) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.activateDeactivateAffiliation(affiliationId, active, token);
  if (res.status === 200) {
    dispatch(partnerGetAffiliations());
    dispatch(getAffiliatedPlayers());
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const affiliatedPlayersReceived = players => ({
  type: 'PARTNER_AFFILIATED_PLAYERS_RECEIVED',
  players
});

export const getAffiliatedPlayers = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getAffiliatedPlayers(token);
  if (res.status === 200) {
    dispatch(affiliatedPlayersReceived(res.json));
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const setPartnerAffiliationsActiveTab = activeTab => ({
  type: 'PARTNER_AFFILIATIONS_ACTIVE_TAB',
  activeTab
});

export const deletePlayerFromAffiliation = (playerId, affiliationId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePlayerFromAffiliation(playerId, affiliationId, token);
  if (res.status === 200) {
    dispatch(getAffiliatedPlayers());
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const editAffiliationPlayer = (affiliationId, playerId, active, medicalCertificateTimestamp, payed) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.editAffiliationPlayer(affiliationId, playerId, active, medicalCertificateTimestamp, payed, token);
  if (res.status === 200) {
    dispatch(getAffiliatedPlayers());
    dispatch(refreshPartnerProfile());
  } else if (res.json.error === 'transaction_present') {
    dispatch(showMessage('MEMBERSHIPS', 'CANT_EDIT_ONLINE_PAID_AFFILIATION'));
  } else if (res.json.error === 'affiliation_not_active') {
    dispatch(showMessage('MEMBERSHIPS', 'AFFILIATION_NOT_ACTIVE'));
  } else if(res.json.error === 'insufficient_funds'){ 
    dispatch(showMessage('MEMBERSHIPS', 'RENEW_AFFILIATION_INSUFFICIENT_FUNDS'));
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const renewPlayerAffiliation = (playerId, affiliationId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.renewPlayerAffiliation(playerId, affiliationId, token);
  if (res.status === 200) {
    dispatch(getAffiliatedPlayers());
    dispatch(refreshPartnerProfile());
    dispatch(showMessage('MEMBERSHIPS', 'PLAYER_AFFILIATION_RENEWED'));
  } else if(res.json.error === 'insufficient_funds'){ 
    dispatch(showMessage('MEMBERSHIPS', 'RENEW_AFFILIATION_INSUFFICIENT_FUNDS'));
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const affiliationRenewRequestsReceived = requests => ({
  type: 'PARTNER_AFFILIATION_RENEW_REQUEST_RECEIVED',
  requests
});

export const getAffiliationRenewRequests = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getAffiliationRenewRequests(token);
  if (res.status === 200) {
    dispatch(affiliationRenewRequestsReceived(res.json));
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}

export const acceptOrRefuseAffiliationRenewRequest = (request, accept) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.acceptOrRefuseAffiliationRenewRequest(request, accept, token);
  if (res.status === 200) {
    dispatch(getAffiliationRenewRequests());
    dispatch(getAffiliatedPlayers());
  } else {
    dispatch(showMessage('MEMBERSHIPS', `Error ${res.status}: ${res.json.error}`));
  }
}
